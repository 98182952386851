import React, { useEffect, useState } from "react";
import mondaySdk from "monday-sdk-js";
import {
  Flex,
  Button,
  Dropdown,
  DialogContentContainer,
  Link,
  Text,
  Icon,
  Tooltip,
} from "monday-ui-react-core";

import { Info } from "monday-ui-react-core/icons";

const monday = mondaySdk();

const buildVersion = require("../../package.json").version;

const AdvancedComponent = ({
  sessionToken,
  showAdvancedSettings,
  onToggleAdvancedSettings,
  boardId,
}) => {
  const BOARD_QUERY = `
  query {
    boards(ids: ${boardId}) {
      name
      columns {
        title
        id
        type
      }
    }
  }
`;
  const [isLoading, setIsLoading] = useState(true);
  const [columnsByType, setColumnsByType] = useState({});
  const [selectedFields, setSelectedFields] = useState({
    start: null,
    finish: null,
    owner: null,
    timeline: null,
    effort: null,
  });

  // Fetch board settings on component mount
  useEffect(() => {
    setIsLoading(true);
    fetch(`/api/boardSettings?boardId=${boardId}&token=${sessionToken}`)
      .then((res) => res.json())
      .then((data) => {
        setSelectedFields(data);

        monday.api(BOARD_QUERY).then((res) => {
          const columns = res.data.boards[0].columns;
          const columnsByType = columns.reduce((acc, column) => {
            if (!acc[column.type]) acc[column.type] = [];
            acc[column.type].push({ label: column.title, value: column.id });
            return acc;
          }, {});

          // Update labels of selectedFields to match current labels in columnsByType
          console.log("SelectedFields:", data);
          console.log("ColumnsByType:", columnsByType);

          const updatedSelectedFields = { ...data };
          for (const type in columnsByType) {
            for (const option of columnsByType[type]) {
                for (const field in updatedSelectedFields) {
                    if (updatedSelectedFields[field] && updatedSelectedFields[field].value === option.value) {
                      updatedSelectedFields[field].label = option.label;
                    }
                  }
            }
          }

          setColumnsByType(columnsByType);
          setSelectedFields(updatedSelectedFields);
          console.log("Updated Selected Fields:", updatedSelectedFields);
        });

        setIsLoading(false);
      })
      .catch((error) => {
        console.error("An error occurred:", error);
        setIsLoading(false);
      });
  }, []);

  const handleDropdownChange = (field) => (selectedOption) => {
    setSelectedFields((prev) => {
      const updatedFields = { ...prev, [field]: selectedOption };

      // Save to server
      fetch(`/api/boardSettings?boardId=${boardId}&token=${sessionToken}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(updatedFields),
      })
        .then((res) => res.json())
        .then((data) => {
          console.log(data.message);
        });

      return updatedFields;
    });
  };

  const getOptions = (type, excludeField) => {
    if (!columnsByType[type]) return [];
    return columnsByType[type].map((option) => ({
      ...option,
      isDisabled: Object.values(selectedFields).some(
        (selectedOption) =>
          selectedOption &&
          selectedOption.value === option.value &&
          excludeField !== selectedOption.field
      ),
    }));
  };

  const handleReAuthClick = (e) => {
    e.preventDefault();
    window.location.href = `/api/auth/auth-monday.js?sessionToken=${sessionToken}`;
  };

  const handleBackClick = () => {
    onToggleAdvancedSettings(!showAdvancedSettings);
  };

  return (
    <>
      <Flex
        className="full-width-flex"
        direction={Flex.directions.COLUMN}
        gap={Flex.gaps.MEDIUM}
      >
        <DialogContentContainer
          type={DialogContentContainer.types.POPOVER}
          className="full-width-flex dialog-content-container"
        >
          <Flex direction={Flex.directions.COLUMN} gap={Flex.gaps.SMALL}>
            <Flex
              direction={Flex.directions.ROW}
              gap={Flex.gaps.SMALL}
              justify={Flex.justify.SPACE_BETWEEN}
            >
              <Flex direction={Flex.directions.COLUMN} gap={Flex.gaps.SMALL}>
                <Flex
                  direction={Flex.directions.ROW}
                  gap={Flex.gaps.SMALL}
                  justify={Flex.justify.SPACE_BETWEEN}
                >
                  <Text weight={Text.weights.BOLD} align={Text.align.END}>
                    Start <span style={{ color: "red" }}>*</span>
                  </Text>
                  <Tooltip
                    content="Set the starting date and time (Date type) for each item using this field. Taskmaster populates this field with the optimized start date after scheduling."
                    withMaxWidth
                  >
                    <Icon icon={Info}></Icon>
                  </Tooltip>
                </Flex>
                <Dropdown
                  isLoading={isLoading}
                  className="wide-dropdown"
                  insideOverflowWithTransformContainer={true}
                  size={Dropdown.size.SMALL}
                  placeholder={"Select"}
                  options={getOptions("date", "start")}
                  onChange={handleDropdownChange("start")}
                  value={selectedFields.start}
                />
              </Flex>
              <Flex direction={Flex.directions.COLUMN} gap={Flex.gaps.SMALL}>
                <Flex
                  direction={Flex.directions.ROW}
                  gap={Flex.gaps.SMALL}
                  justify={Flex.justify.SPACE_BETWEEN}
                >
                  <Text weight={Text.weights.BOLD} align={Text.align.END}>
                    Finish <span style={{ color: "red" }}>*</span>
                  </Text>
                  <Tooltip
                    content="Set the estimated finish date and time (Date type) for each item using this field. Taskmaster populates this field with the optimized finish date after scheduling."
                    withMaxWidth
                  >
                    <Icon icon={Info}></Icon>
                  </Tooltip>
                </Flex>
                <Dropdown
                  isLoading={isLoading}
                  className="wide-dropdown"
                  insideOverflowWithTransformContainer={true}
                  size={Dropdown.size.SMALL}
                  placeholder={"Select"}
                  options={getOptions("date", "finish")}
                  onChange={handleDropdownChange("finish")}
                  value={selectedFields.finish}
                />
              </Flex>
            </Flex>

            <Flex
              direction={Flex.directions.ROW}
              gap={Flex.gaps.SMALL}
              justify={Flex.justify.SPACE_BETWEEN}
            >
              <Flex direction={Flex.directions.COLUMN} gap={Flex.gaps.SMALL}>
                <Flex
                  direction={Flex.directions.ROW}
                  gap={Flex.gaps.SMALL}
                  justify={Flex.justify.SPACE_BETWEEN}
                >
                  <Text weight={Text.weights.BOLD} align={Text.align.END}>
                    Effort <span style={{ color: "red" }}>*</span>
                  </Text>
                  <Tooltip
                    content="The estimated effort required to complete the item (Number type) using this field. Taskmaster uses this during scheduling to forecast resource allocation."
                    withMaxWidth
                  >
                    <Icon icon={Info}></Icon>
                  </Tooltip>
                </Flex>
                <Dropdown
                  isLoading={isLoading}
                  className="wide-dropdown"
                  insideOverflowWithTransformContainer={true}
                  size={Dropdown.size.SMALL}
                  placeholder={"Select"}
                  options={getOptions("numbers", "effort")}
                  onChange={handleDropdownChange("effort")}
                  value={selectedFields.effort}
                />
              </Flex>
              <Flex direction={Flex.directions.COLUMN} gap={Flex.gaps.SMALL}>
                <Flex
                  direction={Flex.directions.ROW}
                  gap={Flex.gaps.SMALL}
                  justify={Flex.justify.SPACE_BETWEEN}
                >
                  <Text weight={Text.weights.BOLD} align={Text.align.END}>
                    Owner
                  </Text>
                  <Tooltip
                    content="Denote the items allocated owner (People type) using this field - first person utilises. Taskmaster uses this information during scheduling to forecast against multiple resources and their availability."
                    withMaxWidth
                  >
                    <Icon icon={Info}></Icon>
                  </Tooltip>
                </Flex>
                <Dropdown
                  isLoading={isLoading}
                  className="wide-dropdown"
                  insideOverflowWithTransformContainer={true}
                  size={Dropdown.size.SMALL}
                  placeholder={"Select"}
                  options={getOptions("people", "owner")}
                  onChange={handleDropdownChange("owner")}
                  value={selectedFields.owner}
                />
              </Flex>
            </Flex>

            <Flex
              direction={Flex.directions.ROW}
              gap={Flex.gaps.SMALL}
              justify={Flex.justify.SPACE_BETWEEN}
            >
              <Flex direction={Flex.directions.COLUMN} gap={Flex.gaps.SMALL}>
                <Flex
                  direction={Flex.directions.ROW}
                  gap={Flex.gaps.SMALL}
                  justify={Flex.justify.SPACE_BETWEEN}
                >
                  <Text weight={Text.weights.BOLD} align={Text.align.END}>
                    Timeline
                  </Text>
                  <Tooltip
                    content="This field displays the start and finish dates (Timeline type) for each item after Taskmaster optimizes the schedule. It provides a high-level view of the overall project schedule."
                    withMaxWidth
                  >
                    <Icon icon={Info}></Icon>
                  </Tooltip>
                </Flex>
                <Dropdown
                  isLoading={isLoading}
                  className="wide-dropdown"
                  insideOverflowWithTransformContainer={true}
                  size={Dropdown.size.SMALL}
                  placeholder={"Select"}
                  options={getOptions("timeline", "timeline")}
                  onChange={handleDropdownChange("timeline")}
                  value={selectedFields.timeline}
                />
              </Flex>
            </Flex>

            {/*<Link text="Re-authenticate Account" onClick={handleReAuthClick} />*/}
            <Button onClick={handleBackClick}>Back</Button>

            <div className="version-info">
              <Text type={Text.types.TEXT3}>
                <i>Build Version: {buildVersion}</i>
              </Text>
            </div>
          </Flex>
        </DialogContentContainer>
      </Flex>
    </>
  );
};

export default AdvancedComponent;
