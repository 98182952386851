import mondaySdk from "monday-sdk-js";
const monday = mondaySdk();
const moment = require("moment");

export default async function commitToMonday(
  scheduledTasks,
  setCurrentStatus,
  setProgress,
  boardId,
  timeZone
) {
  try {
    const maxChunkSize = 25;
    let divisor = Math.ceil(scheduledTasks.length / maxChunkSize);

    // Ensure there are at least 2 chunks if there are more than 25 tasks
    if (scheduledTasks.length > maxChunkSize && divisor < 2) {
      divisor = 2;
    }

    const chunkSize = Math.ceil(scheduledTasks.length / divisor);
    for (let i = 0; i < scheduledTasks.length; i += chunkSize) {
      const tasksChunk = scheduledTasks.slice(i, i + chunkSize);

      setCurrentStatus({
        stage: `Scheduling`,
        progress: i / chunkSize + 1,
        total: Math.ceil(scheduledTasks.length / chunkSize),
      });
      setProgress(i);

      await updateMondayTask(tasksChunk, boardId, timeZone);
    }
    setCurrentStatus({
      stage: `Completed`,
      progress: scheduledTasks.length,
      total: scheduledTasks.length,
    });
  } catch (error) {
    console.log(`Failed to commit tasks to Monday: `, error);
    throw new Error("Failed to commit tasks to Monday");
  }
}

async function updateMondayTask(tasks, bbId, timeZone) {
  let mutationVariables = "(";
  let mutationBody = "";
  let variables = {};

  tasks.forEach((task, index) => {
    const columnValues = {
      [task.fields["start"]]: {
        date: moment(task.startTime).utc().format("YYYY-MM-DD"),
        time: moment(task.startTime).utc().format("HH:mm:ss"),
      },
      [task.fields["finish"]]: {
        date: moment(task.endTime).utc().format("YYYY-MM-DD"),
        time: moment(task.endTime).utc().format("HH:mm:ss"),
      },
    };
    
    if (task.fields["timeline"]) {
      columnValues[task.fields["timeline"]] = {
        from: moment(task.startTime).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
        to: moment(task.endTime).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
      };
    }

    mutationVariables += `$taskId${index}: ID!, $boardId${index}: ID!, $columnValues${index}: JSON!, `;
    mutationBody += `
        updateTask${index}: change_multiple_column_values(item_id: $taskId${index}, board_id: $boardId${index}, column_values: $columnValues${index}) {
          id
        }
      `;

    variables[`taskId${index}`] = parseInt(task.name, 10);
    variables[`boardId${index}`] = bbId;
    variables[`columnValues${index}`] = JSON.stringify(columnValues);
  });

  mutationVariables = mutationVariables.slice(0, -2) + ")"; // Remove the last comma and space, and add the closing parenthesis

  let mutation = "mutation " + mutationVariables + "{" + mutationBody + "}";

  let attempts = 0;
  const maxAttempts = 4;

  while (attempts < maxAttempts) {
    try {
      const response = await monday.api(mutation, { variables });

      if (response && response.data) {
        return;
      } else {
        console.log(`Failed to update tasks: `, response);
      }
    } catch (error) {
      console.log(`Failed to update tasks: `, error);
      attempts++;

      if (attempts === maxAttempts) {
        throw new Error("Failed to update tasks");
      }
      // Sleep for a bit before retrying
      await new Promise((resolve) =>
        setTimeout(resolve, 10000 * (attempts + 1))
      );
    }
  }
}
