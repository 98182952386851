import React, { useState, useEffect } from "react";
import mondaySdk from "monday-sdk-js";
import {
  Checkbox,
  Flex,
  Button,
  Dropdown,
  TextField,
  DialogContentContainer,
  Link,
  Text,
  Badge,
  Tooltip,
  Modal,
  ModalContent,
  ModalFooterButtons,
  ModalHeader,
  Dialog,
  useSwitch,
} from "monday-ui-react-core";
import ScheduleComponent from "../components/ScheduleComponent";
import AdvancedComponent from "../components/AdvancedComponent";

const monday = mondaySdk();

const formatDateTime = (date) => {
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = "00";

  return `${year}-${month}-${day}T${hours}:${minutes}`;
};

const SchedulerMultiStep = ({
  isAuthenticated,
  boardId,
  selectedItems,
  selectedGroup,
  sessionToken,
}) => {
  const [mondayUsers, setMondayUsers] = useState(null);
  const [boardSettings, setBoardSettings] = useState(null);
  const [pulsesToSchedule, setPulses] = useState([]);
  const [showScheduleComponent, setShowScheduleComponent] = useState(false);
  const [selectedOwner, setSelectedOwner] = useState({ value: "undefined" });
  const [areFieldsDisabled, setAreFieldsDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false); // Add this state for loading status
  const [showAdvancedSettings, setShowAdvancedSettings] = useState(false); // Add this state variable
  const [showInfo, setShowInfo] = useState(null); // Add this state variable

  const [earliestStart, setEarliestStart] = useState(
    formatDateTime(new Date())
  );
  const [hasErrors, setHasErrors] = useState(false);

  const [ownerSettings, setOwnerSettings] = useState({
    selectedDays: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"],
  });

  const [startTime, setStartTime] = useState("08:00");
  const [endTime, setEndTime] = useState("17:00");

  const {
    isChecked: clickOutsideButtonActive,
    onChange: switchClickOutsideActive,
  } = useSwitch({
    defaultChecked: false,
  });

  const days = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];

  useEffect(() => {

    handleOwnerChange(undefined);
    
    // Run this only once after the initial render
    monday
      .api(
        `query {
      users {
        id
        name
        photo_small
      }
    }`
      )
      .then((res) => {
        setMondayUsers(res.data.users);
      })
      .catch((err) => {
        console.error(err);
      });

    // On first load, check the settings.
    validateSettings();
  }, []);

  // If the user has navigated back from the advanced settings, we can re-validate the settings
  useEffect(() => {
    if (!showAdvancedSettings) {
      validateSettings();
    }
  }, [showAdvancedSettings]);

  useEffect(() => {
    if (boardSettings) {
      // Get the count of items to schedule
      let query;

      // Select gQL has a limit of 100 params
      if (selectedItems) {
        if (selectedItems.length > 100) {
          setShowInfo(
            "Selected items exceed 100. Only the first 100 will be scheduled."
          );
        }
        query = `
       query {
         items (limit: 100, ids : [${selectedItems.slice(0, 100)}]) {
           id
         }
       }
       `;
      } else if (selectedGroup) {
        query = `
       query {
         boards (ids :${boardId}){
           groups (ids :"${selectedGroup}"){
             items_page(limit: 500) {
              items {
                id
              }
            }
            }
            }
          }
       `;
      } else {
        query = `
       query {
         boards(ids: ${boardId}) {
           items_page(limit: 500)  {
            items {
              id
            }
          }
          }
          }
        }
       `;
      }

      console.log("Query:" + query);

      monday
        .api(query)
        .then((res) => {
          let itemIds = [];
          const response = res.data;

          if (response.items) {
            // Case 1: Direct items array
            /*let filteredItems = response.items.filter((item) => {
              let projectOwnerColumn = item.column_values.find(
                (column) => column.id === boardSettings.owner.value
              );
              return projectOwnerColumn && projectOwnerColumn.value !== null;
            });*/

            itemIds = response.items.map((item) => item.id);
          } else if (response.boards) {
            response.boards.forEach((board) => {
              if (board.items_page && board.items_page.items) {
                // Case 3: Items directly within boards
                board.items_page.items.forEach((item) => itemIds.push(item.id));
              } else if (board.groups) {
                // Case 2: Items within groups within boards
                board.groups.forEach((group) => {
                  if (group.items_page && group.items_page.items) {
                    group.items_page.items.forEach((item) =>
                      itemIds.push(item.id)
                    );
                  }
                });
              }
            });
          }

          setPulses(itemIds);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [boardSettings]);

  // We need to check our settings are valid (fields)
  const validateSettings = async () => {
    const res = await fetch(
      `/api/boardSettings?boardId=${boardId}&token=${sessionToken}`
    );
    const data = await res.json();

    setBoardSettings(data);

    const errors = {};
    let updatedSettings = { ...data };

    // Fetch both the settings and the columns in a single GraphQL query
    const query = `query {
        boards(ids: ${boardId}) {
            name
            columns {
                title
                id
                type
            }
        }
    }`;
    const response = await monday.api(query);
    const columns = response.data.boards[0].columns;
    const columnIds = columns.map((column) => column.id);

    let shouldUpdateSettings = false;

    // Check if all dropdowns are populated and if columns still exist on the board
    for (const field in data) {
      if (field === "owner") continue; // Skip the owner field
      if (field === "timeline") continue; // Skip the timeline field

      if (data[field] === null) {
        errors[field] = "This field is required.";
      } else if (!columnIds.includes(data[field].value)) {
        errors[field] = "This column does not exist on the board.";
        // Unset the invalid field from the stored board settings
        updatedSettings[field] = null;
        shouldUpdateSettings = true;
      }
    }

    // Update the stored board settings if there is something to unset
    if (shouldUpdateSettings) {
      fetch(`/api/boardSettings?boardId=${boardId}&token=${sessionToken}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(updatedSettings),
      });
    }

    if (Object.keys(errors).length > 0) {
      setHasErrors(errors);
    } else {
      setHasErrors(null);
    }
  };

  const handleEarliestStartChange = (e) => {
    setEarliestStart(e);
  };

  const handleOwnerChange = (selectedOwner) => {
    if (!selectedOwner) {
      selectedOwner = { value: "undefined" };
    }

    setSelectedOwner(selectedOwner);

    setIsLoading(true); // Set loading to true
    setAreFieldsDisabled(false);
    fetch(
      `/api/ownerSettings?ownerId=${selectedOwner.value}&boardId=${boardId}&token=${sessionToken}`
    )
      .then((res) => res.json())
      .then((data) => {
        setOwnerSettings(data);
        setStartTime(data.start);
        setEndTime(data.end);
        setIsLoading(false); // Set loading to false after data is fetched
      })
      .catch((error) => {
        console.error("An error occurred:", error);
        setIsLoading(false); // Set loading to false even if an error occurs
      });
  };

  const handleAdvancedClick = (e) => {
    e.preventDefault();
    setShowAdvancedSettings(true);
  };

  const handleSettingsChange = (newSettings) => {
    // If default
    if (!newSettings) {
      newSettings = { value: "undefined" };
    }

    // Update local state
    setOwnerSettings(newSettings);

    // Update server state
    fetch(
      `/api/ownerSettings?ownerId=${selectedOwner.value}&boardId=${boardId}&token=${sessionToken}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(newSettings),
      }
    )
      .then((res) => res.json())
      .then((data) => {
        console.log(data.message);
      });
  };

  const handleCheckboxChange = (day) => {
    console.log("Owner Settings before change:", ownerSettings); // Add this line
    let newSelectedDays = [...(ownerSettings?.selectedDays ?? [])];
    if (newSelectedDays.includes(day)) {
      newSelectedDays = newSelectedDays.filter((d) => d !== day);
    } else {
      newSelectedDays.push(day);
    }
    handleSettingsChange({ ...ownerSettings, selectedDays: newSelectedDays });
  };

  const handleTimeChange = (time, isStart) => {
    const newSettings = { ...ownerSettings, [isStart ? "start" : "end"]: time };
    console.log("Owner Settings before change:", ownerSettings); // Add this line
    console.log("New Settings:", newSettings); // Add this line
    handleSettingsChange(newSettings);

    if (isStart) {
      setStartTime(time);
    } else {
      setEndTime(time);
    }
  };

  if (!mondayUsers) {
    return <div></div>;
  }

  const handleScheduleClick = () => {
    setShowScheduleComponent(true);
  };

  const optionsAvatar = mondayUsers.map((user) => ({
    leftAvatar: user.photo_small,
    value: user.id,
    label: user.name + " - Working Days / Hours",
  }));

  const timeOptions = Array.from({ length: 25 }, (_, index) => {
    const hour = index.toString().padStart(2, "0") + ":00";
    return {
      value: hour,
      label: hour,
    };
  });

  const scheduleButtonLabel = `Schedule ${pulsesToSchedule.length} Item${
    pulsesToSchedule.length > 1 ? "s" : ""
  }`;

  const modifiers = [
    {
      name: "preventOverflow",
      options: {
        mainAxis: false,
      },
    },
  ];

  return (
    <>
      {!showScheduleComponent && (
        <>
          {!showAdvancedSettings ? (
            <Flex
              className="full-width-flex"
              direction={Flex.directions.COLUMN}
              gap={Flex.gaps.SMALL}
            >
              <DialogContentContainer
                type={DialogContentContainer.types.POPOVER}
                className="full-width-flex"
              >
                <Flex direction={Flex.directions.COLUMN} gap={Flex.gaps.MEDIUM}>
                  <Dropdown
                    placeholder={"Default/Unassigned - Working Days / Hours"}
                    options={optionsAvatar}
                    className="ownerDropdown"
                    onChange={handleOwnerChange} // Add this handler
                  />
                  <Flex
                    wrap
                    gap={Flex.gaps.SMALL}
                    justify={Flex.justify.CENTER}
                    align={Flex.align.CENTER}
                  >
                    {days.map((day, index) => (
                      <Checkbox
                        key={index}
                        label={day}
                        checked={
                          ownerSettings?.selectedDays?.includes(day) ?? false
                        }
                        onChange={() => handleCheckboxChange(day)}
                        disabled={areFieldsDisabled || isLoading} // Disable if isLoading is true
                      />
                    ))}
                  </Flex>
                  <Flex
                    gap={Flex.gaps.SMALL}
                    justify={Flex.justify.CENTER}
                    align={Flex.align.CENTER}
                  >
                    <Dropdown
                      className="wide-dropdown"
                      insideOverflowWithTransformContainer={true}
                      clearable={false}
                      value={timeOptions.find((opt) => opt.value === startTime)}
                      size={Dropdown.size.MEDIUM}
                      options={timeOptions.filter((opt) => opt.value < endTime)} // Filter options based on the selected end time
                      disabled={areFieldsDisabled || isLoading} // Disable if isLoading is true
                      onChange={(e) => handleTimeChange(e.value, true)}
                    />

                    <Dropdown
                      className="wide-dropdown"
                      insideOverflowWithTransformContainer={true}
                      clearable={false}
                      value={timeOptions.find((opt) => opt.value === endTime)}
                      size={Dropdown.size.MEDIUM}
                      options={timeOptions.filter(
                        (opt) => opt.value > startTime
                      )} // Filter options based on the selected start time
                      disabled={areFieldsDisabled || isLoading} // Disable if isLoading is true
                      onChange={(e) => handleTimeChange(e.value, false)}
                    />
                  </Flex>
                </Flex>
              </DialogContentContainer>

              <DialogContentContainer
                type={DialogContentContainer.types.POPOVER}
                className="full-width-flex"
              >
                <Flex direction={Flex.directions.COLUMN} gap={Flex.gaps.MEDIUM}>
                  <Flex
                    className="full-width-flex"
                    wrap
                    gap={Flex.gaps.SMALL}
                    justify={Flex.justify.CENTER}
                    align={Flex.align.CENTER}
                  >
                    <TextField
                      title={"Earliest Start"}
                      size={TextField.sizes.MEDIUM}
                      type={TextField.types.DATE_TIME}
                      onChange={handleEarliestStartChange}
                      value={earliestStart}
                    />
                  </Flex>
                  {hasErrors ? (
                    <Badge>
                      <Link text="Advanced" onClick={handleAdvancedClick} />
                    </Badge>
                  ) : (
                    <Link text="Advanced" onClick={handleAdvancedClick} />
                  )}{" "}
                </Flex>
              </DialogContentContainer>
              <Flex
                id={"HIDE_TRIGGERS_CONTAINER"}
                justify={Flex.justify.END}
                style={{ width: "100%" }}
              >
                <Tooltip
                  zIndex={999}
                  shouldShowOnMount={!!showInfo}
                  content={
                    hasErrors && Object.keys(hasErrors).length > 0
                      ? "Field configuration has not been set for the following fields: " +
                        Object.keys(hasErrors).join(", ") +
                        ". Please update the 'advanced' settings."
                      : pulsesToSchedule.length === 0
                      ? "No items available to schedule"
                      : showInfo
                      ? showInfo
                      : ""
                  }
                >
                  <Dialog
                    modifiers={modifiers}
                    onClickOutside={switchClickOutsideActive}
                    position={"top-start"}
                    open={clickOutsideButtonActive}
                    showTrigger={[Dialog.hideShowTriggers.CLICK]}
                    hideTrigger={[Dialog.hideShowTriggers.CLICK_OUTSIDE]}
                    content={
                      <DialogContentContainer>
                        <Flex
                          style={{ width: "200px" }}
                          direction={Flex.directions.COLUMN}
                          gap={Flex.gaps.MEDIUM}
                        >
                          <Text align={Text.align.CENTER} maxLines={3}>
                            Scheduling will overwrite the timeline and
                            start/finish dates for{" "}
                            <b>{pulsesToSchedule.length}</b> items.
                          </Text>
                          <Button
                            color={Button.colors.POSITIVE}
                            onClick={handleScheduleClick}
                          >
                            Confirm
                          </Button>
                        </Flex>
                      </DialogContentContainer>
                    }
                  >
                    <Button
                      disabled={
                        hasErrors ||
                        pulsesToSchedule.length === 0 ||
                        clickOutsideButtonActive
                      }
                      active={clickOutsideButtonActive}
                      onClick={switchClickOutsideActive}
                    >
                      {scheduleButtonLabel}
                    </Button>
                  </Dialog>
                </Tooltip>
              </Flex>
            </Flex>
          ) : (
            <AdvancedComponent
              sessionToken={sessionToken}
              showAdvancedSettings={showAdvancedSettings}
              boardId={boardId}
              onToggleAdvancedSettings={setShowAdvancedSettings}
            />
          )}
        </>
      )}
      {showScheduleComponent && (
        <ScheduleComponent
          isAuthenticated={isAuthenticated}
          boardId={boardId}
          pulsesToSchedule={pulsesToSchedule}
          sessionToken={sessionToken}
          earliestStart={earliestStart}
        />
      )}
    </>
  );
};

export default SchedulerMultiStep;
