import React from 'react';
import {
  DialogContentContainer,
  Flex,
  Skeleton
} from "monday-ui-react-core";

const LoadingComponent = () => (
  <DialogContentContainer>
    <Flex className="full-width-flex" gap={Flex.gaps.SMALL} justify={Flex.justify.START} direction={Flex.directions.COLUMN}>
      <Skeleton fullWidth={true} height={46} />
      <Skeleton fullWidth={true} height={66} />
      <Skeleton fullWidth={true} height={46} />
      <Flex justify={Flex.justify.END} style={{ width: "100%" }}>
        <Skeleton width={100} height={46} />
      </Flex>
    </Flex>
  </DialogContentContainer>
);

export default LoadingComponent;
