import React, { useState, useEffect } from 'react';
import mondaySdk from 'monday-sdk-js';
import './App.css';

import { AttentionBox, Box, Flex } from 'monday-ui-react-core';
import "monday-ui-react-core/tokens";

import LoadingComponent from './components/LoadingComponent';  // Import the LoadingComponent
import SchedulerMultiStep from './components/SchedulerMultiStep';  // Import the ScheduleComponent
import { Analytics } from "@vercel/analytics/react"

<link
  href="https://fonts.googleapis.com/css2?family=Figtree:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"
  rel="stylesheet"
/>

const monday = mondaySdk();

function App() {
  const [isLoading, setIsLoading] = useState(true);
  const [sessionToken, setSessionToken] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [subscription, setSubscription] = useState(null);
  const [isViewOnly, setIsViewOnly] = useState(false);

  const [boardId, setBoardId] = useState(null);
  const [selectedItems, setSelectedItems] = useState(null);
  const [selectedGroup, setSelectedGroup] = useState(null);

  useEffect(() => {
    monday.get('sessionToken').then((res) => {
      setSessionToken(res.data);
    });

    monday.listen('context', (res) => {
      console.log('Context', JSON.stringify(res.data));
      //setSubscription(res.data.subscription); TODO
      setSubscription({ plan_id: "Standard" });
      setBoardId(res.data.boardId);
      setSelectedItems(res.data.selectedPulsesIds);
      setSelectedGroup(res.data.groupId);
    });
  }, []);

  useEffect(() => {
    if (sessionToken) {
      fetch(`${process.env.REACT_APP_API_BASE_URL}/auth/hasAuth`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token: sessionToken }),
      })
        .then((res) => res.json())
        .then((data) => {
          console.log('Validating session token', data);
          setIsLoading(false);
          if (data?.tokenData?.is_view_only) {
            setIsViewOnly(true);
          }
          else if (data.isValid) {
            setIsAuthenticated(true);
          } else {
            monday.get("location")
              .then(
                res => {
                  const url = new URL(res.data.href);
                  const subdomain = url.hostname.split('.')[0];
                  window.location.href = `/api/auth/auth-monday.js?sessionToken=${sessionToken}&subdomain=${subdomain}`;
                }
              )
          }
        })
        .catch((error) => {
          console.error('Error validating session token', error);
          setIsLoading(false);
        });
    }
  }, [sessionToken]);

  return (
    <div className="App">
      <Analytics />
      <Box padding={Box.paddings.MEDIUM}>
        {isLoading ? (
          <LoadingComponent />
        ) : (
          isViewOnly ? (
            <Flex
              className="full-width-flex"
              direction={Flex.directions.COLUMN}
              gap={Flex.gaps.SMALL}
            >
              <AttentionBox
                title="Viewer"
                text="As a viewer, you are unable to use this app."
              />
            </Flex>
          ) : !subscription?.plan_id ? (
            <Flex
              className="full-width-flex"
              direction={Flex.directions.COLUMN}
              gap={Flex.gaps.SMALL}
            >
              <AttentionBox
                title="No subscription"
                text="No subscription to this app is available."
              />
            </Flex>
          ) : isAuthenticated ? (
            <SchedulerMultiStep
              isAuthenticated={isAuthenticated}
              boardId={boardId}
              selectedItems={selectedItems}
              selectedGroup={selectedGroup}
              sessionToken={sessionToken}
            />
          ) : (
            <LoadingComponent />
          )
        )}
      </Box>
    </div >
  );
}

export default App;