import React, { useEffect, useState } from "react";
import commitToMonday from "../helpers/scheduler";
import { track } from "@vercel/analytics";

import {
  LinearProgressBar,
  Loader,
  DialogContentContainer,
  Flex,
  Skeleton,
  Text,
} from "monday-ui-react-core";
import { Heading } from "monday-ui-react-core/next";
import { v4 as uuidv4 } from "uuid";
import ConfettiExplosion from "react-confetti-explosion";

const momentTz = require("moment-timezone");

const ScheduleComponent = ({
  isAuthenticated,
  boardId,
  pulsesToSchedule,
  sessionToken,
  earliestStart,
  userId,
}) => {
  const [scheduleUUID, setScheduleUUID] = useState(null);
  const [error, setError] = useState(null);
  const [progress, setProgress] = useState(0);
  const [totalTasks, setTotalTasks] = useState(0);
  const [isCompleted, setIsCompleted] = useState(false);
  const [isScheduling, setIsScheduling] = useState(false);
  const [bId, setBoardId] = useState(boardId);
  const [currentStatus, setCurrentStatus] = useState({
    stage: "Loading",
    message: "",
  });

  const startScheduling = () => {
    if (isScheduling) return;
    if (scheduleUUID) return;

    setIsScheduling(true);

    const newUUID = uuidv4();
    setScheduleUUID(newUUID);

    const timeZone = momentTz.tz.guess();

    if (isAuthenticated && boardId) {
      fetch(`${process.env.REACT_APP_API_BASE_URL}/schedule`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          uuid: newUUID,
          token: sessionToken,
          earliestStart: earliestStart,
          timeZone: timeZone,
          pulsesToSchedule: pulsesToSchedule,
          variables: { boardId },
          userId: userId,
        }),
      })
        .then((res) => {
          if (!res.ok) {
            return res.json().then((err) => {
              throw err;
            });
          }
          return res.json();
        })
        .then((data) => {
          // Handle data here
          setTotalTasks(data.length);
          track("Schedules", { taskCount: data.length });

          commitToMonday(
            data,
            setCurrentStatus,
            setProgress,
            bId,
            timeZone
          ).then(() => {
            setIsCompleted(true);
          });
        })
        .catch((error) => {
          if (error.status === "Error") {
            setCurrentStatus({
              stage: "Failed",
              message: error.message,
            });
            setError(error.message);
          } else {
            setCurrentStatus({
              stage: "Failed",
              message: "There was a problem initiating scheduling. ",
            });
            console.error("Error fetching data", error);
            setError("There was a problem initiating scheduling. ");
          }
        })
        .finally(() => {
          setIsScheduling(false);
        });
    }
  };

  const calculateProgressPercent = () => {
    if (totalTasks === 0) return 0;
    return Math.floor((progress / totalTasks) * 100);
  };

  useEffect(() => {
    setBoardId(boardId);
    startScheduling();
  }, []);

  const messages = [
    "Hang tight, we're organizing your awesomeness!",
    "Hold on, we're making your data dance!",
    "Just a moment, we're aligning all the stars...",
    "Sit tight, we're polishing your data gems!",
    "Almost there, putting the pieces together!",
    "Give us a sec, we're crafting your masterpiece!",
    "Loading... because good things take time.",
    "Prepping your data, hold tight for brilliance!",
    "Getting things ready... almost there!",
    "Hold up, we're tidying up your data!",
    "Loading your data... because magic takes a moment.",
    "Cooking up your data... it'll be ready soon!",
    "Assembling your info... stay tuned!",
    "Sorting things out... almost ready!",
    "Fetching your data... please hold!",
    "Syncing the dots... just a bit longer!",
    "Loading... we're on it!",
    "Unpacking your data... one moment!",
  ];

  const randomMessage = messages[Math.floor(Math.random() * messages.length)];

  return (
    <>
      <DialogContentContainer type={DialogContentContainer.types.POPOVER}>
        {isCompleted ? (
          <Flex
            className="full-width-flex"
            gap={Flex.gaps.SMALL}
            justify={Flex.justify.CENTER}
            direction={Flex.directions.COLUMN}
          >
            <ConfettiExplosion />
            <div className="animation-ctn">
              <div className="icon icon--order-success svg">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="154px"
                  height="154px"
                >
                  <g fill="none" stroke="#22AE73" strokeWidth="2">
                    <circle
                      cx="77"
                      cy="77"
                      r="72"
                      style={{
                        strokeDasharray: "480px, 480px",
                        strokeDashoffset: "960px",
                      }}
                    />
                    <circle
                      id="colored"
                      fill="#22AE73"
                      cx="77"
                      cy="77"
                      r="72"
                      style={{
                        strokeDasharray: "480px, 480px",
                        strokeDashoffset: "960px",
                      }}
                    />
                    <polyline
                      className="st0"
                      stroke="#fff"
                      strokeWidth="10"
                      points="43.5,77.8 63.7,97.9 112.2,49.4"
                      style={{
                        strokeDasharray: "100px, 100px",
                        strokeDashoffset: "200px",
                      }}
                    />
                  </g>
                </svg>
              </div>
            </div>

            <Heading type={Heading.types.H2}>Complete</Heading>
            <Text
              element="span"
              type={Text.types.TEXT1}
              style={{
                width: "75%",
                textAlign: "center",
              }}
              ellipsis={false}
            >
              All your tasks have been successfully re-scheduled.
            </Text>
          </Flex>
        ) : error ||
          currentStatus.stage === "Failed" ||
          currentStatus.status === "Error" ? (
          <>
            <Flex
              className="full-width-flex"
              gap={Flex.gaps.SMALL}
              justify={Flex.justify.CENTER}
              direction={Flex.directions.COLUMN}
            >
              <div className="animation-ctn">
                <div className="icon icon--order-fail svg">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="154px"
                    height="154px"
                  >
                    <g fill="none" stroke="#FF3636" strokeWidth="2">
                      <circle
                        cx="77"
                        cy="77"
                        r="72"
                        style={{
                          strokeDasharray: "480px, 480px",
                          strokeDashoffset: "960px",
                        }}
                      />
                      <circle
                        id="colored"
                        fill="#FF3636"
                        cx="77"
                        cy="77"
                        r="72"
                        style={{
                          strokeDasharray: "480px, 480px",
                          strokeDashoffset: "960px",
                        }}
                      />
                      <line x1="39.5" y1="39.5" x2="114.5" y2="114.5" />
                      <line x1="114.5" y1="39.5" x2="39.5" y2="114.5" />
                    </g>
                  </svg>
                </div>
              </div>
              <Heading type={Heading.types.H2}>Failed</Heading>
              <Text
                element="span"
                type={Text.types.TEXT1}
                style={{
                  width: "75%",
                  textAlign: "center",
                }}
                ellipsis={false}
              >
                {error ? error : currentStatus.message}
              </Text>
            </Flex>
          </>
        ) : typeof progress === "undefined" ||
          typeof totalTasks === "undefined" ||
          totalTasks === 0 ||
          currentStatus.stage === "Starting" ? (
          <Flex
            className="full-width-flex"
            gap={Flex.gaps.SMALL}
            justify={Flex.justify.START}
            direction={Flex.directions.COLUMN}
          >
            <Flex>
              <Text align={"center"}>{randomMessage} </Text>
              <Loader size={20} />
            </Flex>
            <Skeleton fullWidth={true} height={146} />
            <Skeleton fullWidth={true} height={46} />
            <Skeleton fullWidth={true} height={16} />
          </Flex>
        ) : (
          <>
            <img width="100%" alt="loading" src="./schedulingMonkey.gif" />
            <div className="full-width-flex">
              <Flex justify={Flex.justify.CENTER}>
                <span>
                  {`Scheduling Progress ${Math.round(
                    (progress / totalTasks) * 100
                  )}%`}{" "}
                </span>
                <Loader size={10} />
              </Flex>{" "}
            </div>
            <LinearProgressBar
              className="full-width-flex"
              value={calculateProgressPercent()}
              animated={true}
              size={LinearProgressBar.sizes.LARGE}
              barStyle={LinearProgressBar.styles.POSITIVE}
            />
          </>
        )}
      </DialogContentContainer>
    </>
  );
};

export default ScheduleComponent;
